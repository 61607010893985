import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import customIcons from '../consts/custom-icons.const';

Vue.use(Vuetify);

export default new Vuetify({
   icons: {
      values: customIcons,
   },
});
